var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "mb-5", attrs: { fluid: "" } },
    [
      _c(
        "div",
        { staticClass: "filter-bar p-2 mb-3" },
        [
          _c(
            "b-overlay",
            { attrs: { show: _vm.loading } },
            [
              _c(
                "b-navbar",
                { attrs: { toggleable: "xl" } },
                [
                  _c("b-navbar-toggle", {
                    attrs: { target: "filter-collapse" }
                  }),
                  _c(
                    "b-collapse",
                    { attrs: { id: "filter-collapse", "is-nav": "" } },
                    [
                      _c("b-form-select", {
                        staticClass: "select company",
                        attrs: {
                          options: _vm.companies,
                          "text-field": "name",
                          "value-field": "id",
                          plain: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "first",
                            fn: function() {
                              return [
                                _c(
                                  "b-form-select-option",
                                  { attrs: { value: null } },
                                  [_vm._v("Select Company")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.filters.company_id,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "company_id", $$v)
                          },
                          expression: "filters.company_id"
                        }
                      }),
                      _c("b-form-select", {
                        staticClass: "select company",
                        attrs: {
                          options: _vm.companytypes,
                          "text-field": "type",
                          "value-field": "id",
                          plain: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "first",
                            fn: function() {
                              return [
                                _c(
                                  "b-form-select-option",
                                  { attrs: { value: null } },
                                  [_vm._v("Company Type")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.filters.companytype_id,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "companytype_id", $$v)
                          },
                          expression: "filters.companytype_id"
                        }
                      }),
                      _c(
                        "button",
                        {
                          staticClass: "filter-btn search mx-2",
                          on: {
                            click: function($event) {
                              return _vm.search()
                            }
                          }
                        },
                        [_vm._v("Search")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "filter-btn reset",
                          on: {
                            click: function($event) {
                              return _vm.resetFilters()
                            }
                          }
                        },
                        [_vm._v("Reset Filters")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      !_vm.loading
        ? _c(
            "b-overlay",
            { attrs: { show: _vm.searching } },
            [
              _c(
                "b-card",
                { staticClass: "card-border table-card text--black" },
                [
                  _c("b-table", {
                    attrs: {
                      items: _vm.report,
                      fields: _vm.fields,
                      striped: "",
                      small: ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "cell(qbpath)",
                          fn: function(data) {
                            return [
                              _c(
                                "span",
                                {
                                  attrs: {
                                    title: data.item.qbpath || "Not Set!"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      data.item.qbpath !== null
                                        ? data.item.qbpath
                                            .split("\\")
                                            .pop()
                                            .split("/")
                                            .pop()
                                        : ""
                                    )
                                  )
                                ]
                              )
                            ]
                          }
                        },
                        {
                          key: "cell(id)",
                          fn: function(data) {
                            return [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    role: "button",
                                    size: "sm",
                                    variant: "info",
                                    to: "/admin/company/" + data.item.id
                                  }
                                },
                                [_c("b-icon-pencil-fill")],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      602387913
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }